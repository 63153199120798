import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF'
  }
}))

function AnsweredQuestions() {
  return (
    <div>
      <h1>Obrigado pelo contato!</h1>
      <h2>Em breve entraremos em contato</h2>
    </div>
  )
}

export default AnsweredQuestions