import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
  backgroundQuestionNumber: {
    height: 'auto',
    fontFamily: 'BrandonGrotesque',
    textAlign: 'left',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '120px',
      width: '27px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        width: '70px',
        height: '310px',
        marginLeft: '-150px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '40px',
        height: '135px',
        marginLeft: '-75px'
      },
      [theme.breakpoints.down('md')]: {
        width: '37px',
        height: '135px',
        marginLeft: '-70px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '110px',
        marginLeft: '-55px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '50px',
        marginLeft: '-25px',
        marginTop: '10px'
      }
    }
  },
  titleNumber: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: '10px'
    }
  },
  hintNumber: {
    marginBottom: '20px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 42,
      padding: '15px 0px 50px 0px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      paddingTop: '5px'
    }
  },
  fieldNumber: {
    paddingBottom: '10px',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      paddingBottom: '100px',
    },
    '& input': {
      [theme.breakpoints.up('xl')]: {
        fontSize: 42,
        width: '1000px'
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 25,
        width: '750px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        width: '550px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        width: '350px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        width: '220px'
      }
    }
  }
}))

function QuestionNumber({ setChecked, keyListener, question, updateData, setValidStep }) {
  const classes = useStyles()
  const [fieldError, setFieldError] = useState("")
  
  useEffect(() => {
    if (question.value) {
      handleValidation()
    }
    setChecked(true)
  }, [])

  function handleKeyListener(event) {
    if (handleValidation()) {
      keyListener(event, question)
    } else if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault()
    }
  }

  function validate(question){
    if (question.required && (question.value == "" || question.value == null))
      return false
    return true
  }

  function handleValidation() {
    const isValid = validate(question)
    if (!isValid) {
      setFieldError("Campo obrigatório!")
      setValidStep(false)
      return false
    } else {
      setFieldError("")
      setValidStep(true)
      return true
    }
  }

  function handleChange(e) {
    updateData(question.id, e.target.value)
    handleValidation()
  }

  return (
    <motion.div initial={{ x: '30vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', stiffness: 40 }}>
      <div className={classes.backgroundQuestionNumber}>
        <Typography className={classes.titleNumber}>{question.title}</Typography>
        <Typography className={classes.hintNumber}>{question.hint}</Typography>
        <TextField value={question.value || ""} onKeyDown={handleKeyListener} autoFocus className={classes.fieldNumber} id='questionNumber' name='questionNumber' type='number' onChange={handleChange} placeholder={'Responda aqui'} />
        {fieldError ? <div style={{ color: 'red' }}>{fieldError}</div> : null}
      </div>
    </motion.div>
  )
}

export default QuestionNumber