import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import MarcaWeb2 from 'images/marca_web2.png'
import Facebook from 'images/facebook.svg'
import Instagram from 'images/instagram.svg'
import Twitter from 'images/twitter.svg'
import Linkedin from 'images/linkedin.svg'

const useStyles = makeStyles((theme) => ({
  backgroundFooter: {
    height: 'auto',
    backgroundColor: '#232020',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  copyright: {
    float: 'left',
    color: '#FFFFFF',
    paddingTop: '0px',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center',
      paddingTop: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      textAlign: 'center',
      paddingTop: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: 'center',
      paddingTop: '10px',
      float: 'none'
    }
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '10px 10px 0px 0px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '400px'      
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  socialNetwork: {
    height: '20px',
    filter: 'invert(100%) sepia(0%) saturate(7482%) hue-rotate(282deg) brightness(109%) contrast(106%)'
  },
  brandFooter: {
    width: '60px',
    marginLeft: '7px'
  }
}));

function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.backgroundFooter}>
      <Container style={{ padding: '0px' }}>
        <Typography  className={classes.copyright}>© {(new Date()).getFullYear()} <img src={MarcaWeb2} alt='roadmapps' className={classes.brandFooter} /></Typography>
      </Container>
      <Container className={classes.socialMedia}>
        <a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/rdmapps'><img src={Facebook} alt='facebook' className={classes.socialNetwork} /></a>
        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/rdmapps/'><img src={Instagram} alt='instagram' className={classes.socialNetwork} /></a>
        <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/rdmappsbr'><img src={Twitter} alt='twitter' className={classes.socialNetwork} /></a>
        <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/company/rdmapps/'><img src={Linkedin} alt='linkedin' className={classes.socialNetwork} /></a>
      </Container>
    </div>
  )
}

export default Footer