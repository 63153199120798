import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import axios from 'axios'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Aos from 'aos'
import 'aos/dist/aos.css'

const theme = createMuiTheme({
  typography: {
    fontFamily: 'BrandonGrotesque'
  },
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'transparent !important'
      },
      root: {
        '&.Mui-focused': {
          borderColor: 'transparent !important'
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#FFFFFF',
          backgroundColor: '#232020',
          outline: 'none'
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  backgroundContact: {
    height: 'auto',
    backgroundColor: '#232020'
  },
  moreContactImg: {
    width: '20px',
    height: '20px',
    padding: '0px 40px',
    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
      padding: '0px 10px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '15px',
      height: '15px'
    }
  },
  contactComp: {
    flexGrow: 1,
    paddingLeft: '125px',
    color: '#FFFFFF',
    fontSize: 30,
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      paddingLeft: '75px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      textAlign: 'center',
      fontSize: 22
    },
    "&::before": {
      left: 0,
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '170px',
      width: '50px',
      marginLeft: '27px',
      marginTop: '-30px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.down('md')]: {
        height: '100px',
        width: '45px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '90px',
        width: '40px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '85px',
        width: '35px'
      }
    }
  },
  headerContact: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40px',
    padding: '40px 0px',
    marginLeft: '10px'
  },
  formContact: {
    marginTop: '-15px',
    textAlign: 'center',
    '& > *': {
      margin: theme.spacing(1),
      backgroundColor: '#EDEDED',
      [theme.breakpoints.up('xl')]: {
        width: '75ch'
      },
      [theme.breakpoints.down('xl')]: {
        width: '67ch'
      },
      [theme.breakpoints.down('lg')]: {
        width: '47ch'
      },
      [theme.breakpoints.down('md')]: {
        width: '35ch'
      },
      [theme.breakpoints.down('sm')]: {
        width: '21ch'
      },
      [theme.breakpoints.down('xs')]: {
        width: '36ch'
      }
    }
  },
  fieldContact: {
    border: '1px solid #EDEDED',
    '& label': {
      color: '#EDEDED',
      fontWeight: 'bold'
    },
    '& input': {
      color: '#EDEDED',
      backgroundColor: '#232020',
    }
  },
  fieldContactError: {
    border: '1px solid #d52e5f',
    '& label': {
      color: '#EDEDED',
      fontWeight: 'bold'
    },
    '& input': {
      color: '#EDEDED',
      backgroundColor: '#232020',
    }
  },
  fieldTextContact: {
    border: '1px solid #EDEDED',
    '& label': {
      color: '#EDEDED',
      fontWeight: 'bold'
    },
    '& textarea': {
      color: '#EDEDED',
      backgroundColor: '#232020'
    },
    '&': {
      margin: theme.spacing(1),
      width: '123.25ch',
      backgroundColor: '#232020',
      [theme.breakpoints.up('xl')]: {
        width: '215.4ch'
      },
      [theme.breakpoints.down('xl')]: {
        width: '205.4ch'
      },
      [theme.breakpoints.down('lg')]: {
        width: '145.4ch'
      },
      [theme.breakpoints.down('md')]: {
        width: '109.4ch'
      },
      [theme.breakpoints.down('sm')]: {
        width: '67ch'
      },
      [theme.breakpoints.down('xs')]: {
        width: '36ch'
      }
    }
  },
  fieldTextContactError: {
    border: '1px solid #d52e5f',
    '& label': {
      color: '#EDEDED',
      fontWeight: 'bold'
    },
    '& textarea': {
      color: '#EDEDED',
      backgroundColor: '#232020'
    },
    '&': {
      margin: theme.spacing(1),
      width: '123.25ch',
      backgroundColor: '#232020',
      [theme.breakpoints.up('xl')]: {
        width: '215.4ch'
      },
      [theme.breakpoints.down('xl')]: {
        width: '205.4ch'
      },
      [theme.breakpoints.down('lg')]: {
        width: '145.4ch'
      },
      [theme.breakpoints.down('md')]: {
        width: '109.4ch'
      },
      [theme.breakpoints.down('sm')]: {
        width: '67ch'
      },
      [theme.breakpoints.down('xs')]: {
        width: '36ch'
      }
    }
  },
  buttonContact: {
    color: '#FFFFFF',
    padding: '7px 50px',
    fontSize: 18,
    width: '103ch',
    backgroundColor: 'transparent',
    border: '1px solid #ECECEC',
    borderRadius: '0px',
    marginBottom: '50px',
    [theme.breakpoints.up('xl')]: {
      width: '155ch'
    },
    [theme.breakpoints.down('xl')]: {
      width: '144.75ch'
    },
    [theme.breakpoints.down('lg')]: {
      width: '102.5ch'
    },
    [theme.breakpoints.down('md')]: {
      width: '77ch'
    },
    [theme.breakpoints.down('sm')]: {
      width: '47.5ch'
    },
    [theme.breakpoints.down('xs')]: {
      width: '25.5ch'
    }
  },
  recaptcha: {
    width: '300px',
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top'
  }
}))

function Contact() {
  const classes = useStyles()
  const [contact, setContact] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })
  const [errors, setErrors] = useState({})
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [fieldErrorName, setFieldErrorName] = useState("")
  const [fieldErrorEmail, setFieldErrorEmail] = useState("")
  const [fieldErrorSubject, setFieldErrorSubject] = useState("")
  const [fieldErrorMessage, setFieldErrorMessage] = useState("")
  const [readyForCaptcha, setReadyForRecaptcha] = useState(false)
  const recaptchaRef = React.createRef();

  function sendContact(e) {
    e.preventDefault();
    setButtonDisabled(true);
    axios.post(
      '/home/contact.json', { contact_us: contact, 'g-recaptcha-response': contact.recaptchaValue }
    )
    .then(function(){
      alertSendMail()
      setReadyForRecaptcha(false);
      setContact({name: '', email: '', subject: '', message: ''})
      setErrors({})
    })
    .catch(function(response){
      if (response.status == 422){
        setErrors(response.data.errors)
      } else {
        setErrors({})
      }
      if (response.data && response.data.error) {
        setErrors(response.data.errors)
      } else {
        alertSendMailError()
      }
    })
    .finally(function () {
      setButtonDisabled(false);
    })
  }

  function alertSendMail() {
    swal("E-mail enviado!", "Entraremos em contato em breve", "success")
  }

  function alertSendMailError() {
    swal("E-mail não enviado!", "Verifique se todos os campos foram preenchidos", "error")
  }

  useEffect(() => {
    Aos.init({ duration: 1200 })
  }, [])

  useEffect(function () {
    enableButton();
  }, [contact])

  function recaptchaChange(value){
    setContact({ ...contact, recaptchaValue: value })
  }

  // SEPAREI PARA O CASO DE CASA CAMPO TER UMA VALIDAÇÃO MUITO ESPECÍFICA (COMO É O CASO DO E-MAIL)
  function handleName(e){
    setContact({ ...contact, name: e.target.value })
    handleValidationName(e)
  }

  function handleEmail(e) {
    setContact({ ...contact, email: e.target.value })
    handleValidationEmail(e)
  }

  function handleSubject(e){
    setContact({ ...contact, subject: e.target.value })
    handleValidationSubject(e)
  }

  function handleMessage(e) {
    setContact({ ...contact, message: e.target.value })
    handleValidationMessage(e)
  }

  function handleValidationName(e) {
    if (validString(e.target.value)) {
      setFieldErrorName("")
      return true
    } else {
      setFieldErrorName("Campo obrigatório!")
      return false
    }
  }

  function handleValidationEmail(e) {
    if (validEmail(e.target.value)) {
      setFieldErrorEmail("")
      return true
    } else {
      setFieldErrorEmail("Campo obrigatório!")
      return false
    }
  }

  function handleValidationSubject(e) {
    if (validString(e.target.value)) {
      setFieldErrorSubject("")
      return true
    } else {
      setFieldErrorSubject("Campo obrigatório!")
      return false
    }
  }

  function handleValidationMessage(e) {
    if (validString(e.target.value)) {
      setFieldErrorMessage("")
      return true
    } else {
      setFieldErrorMessage("Campo obrigatório!")
      return false
    }
  }

  function validString(value) {
    return value != null && value != "" && value.length > 1
  }

  function validEmail(email) {
    return String(email)
             .toLowerCase()
             .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
             );
  }

  function enableButton() {
    setButtonDisabled(
      !(
        validEmail(contact.email) &&
        validString(contact.name) &&
        validString(contact.subject) &&
        validString(contact.message) &&
        validString(contact.recaptchaValue)
      )
    );
    setReadyForRecaptcha(
      validString(contact.email) &&
      validString(contact.name) &&
      validString(contact.subject) &&
      validString(contact.message)
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.backgroundContact} id='contact'>
        <div className={classes.headerContact}>
          <Typography className={classes.contactComp}>CONTATO</Typography>
        </div>
        <form className={classes.formContact} noValidate autoComplete="off" onSubmit={sendContact}>
          <TextField className={fieldErrorName ? classes.fieldContactError : classes.fieldContact} id="name" name='name' value={contact['name']} onBlur={handleName} onChange={handleName} label="Nome" variant="outlined" />
          <TextField className={fieldErrorEmail ? classes.fieldContactError : classes.fieldContact} id="email" name='email' value={contact['email']} onBlur={handleEmail} onChange={handleEmail} label="E-mail" variant="outlined" />
          <TextField className={fieldErrorSubject ? classes.fieldContactError : classes.fieldContact} id="subject" name='subject' value={contact['subject']} onBlur={handleSubject} onChange={handleSubject} label="Assunto" variant="outlined" />
          <TextField
            id="message"
            name='message'
            value={contact['message']}
            className={fieldErrorMessage ? classes.fieldTextContactError : classes.fieldTextContact}
            onBlur={handleMessage}
            onChange={handleMessage}
            multiline
            rows={4}
            label="Mensagem"
            variant="outlined"
          />
          {readyForCaptcha && <ReCAPTCHA className={classes.recaptcha} onChange={recaptchaChange} ref={recaptchaRef} sitekey="6LcKlzUjAAAAALfcLksU1NttmrNr9267GqKbaM_i" />}
          <Button disabled={buttonDisabled} className={classes.buttonContact} variant="contained" type="submit">ENVIAR</Button>
        </form>
      </div>
    </ThemeProvider>
  )
}

export default Contact