import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import bgHome from 'images/fundo3.png'
import bgHomeMobile from 'images/fundo1.png'
import MarcaQuest from 'images/marca_v2.png'
import MarcaQuestMobile from 'images/marca_web.png'
import Typography from '@material-ui/core/Typography'
import QuestionText from '../question_components/QuestionText'
import QuestionBigText from '../question_components/QuestionBigText'
import QuestionNumber from '../question_components/QuestionNumber'
import QuestionCurrency from '../question_components/QuestionCurrency'
import QuestionYesNo from '../question_components/QuestionYesNo'
import QuestionSelectionList from '../question_components/QuestionSelectionList'
import QuestionFixedValue from '../question_components/QuestionFixedValue'
import QuestionTitle from '../question_components/QuestionTitle'
import QuestionCheckboxSelectionList from '../question_components/QuestionCheckboxSelectionList'
import Button from '@material-ui/core/Button'
import MobileStepper from '@material-ui/core/MobileStepper'
import swal from 'sweetalert'
import axios from 'axios'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import './Question.css'

const theme = createMuiTheme({
  paper: {
    zIndex: 1,
    position: 'relative'
  },
  typography: {
    fontFamily: 'BrandonGrotesque'
  },
  overrides: {
    MuiMobileStepper: {
      progress: {
        width: '15%',
        borderRadius: '12px',
        padding: '3px'
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: 'black',
        border: '1px solid white'
      },
      barColorPrimary: {
        backgroundColor: 'white'
      },
      bar1Determinate: {
        border: '1.5px solid black',
        borderRadius: '15px',
        maxWidth: '98%'
      }
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#d53e5f !important',
          color: '#fff !important'
        }
      }
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: '2px solid #d52e5f'
        }
      }
    }
  }
})
const useStyles = makeStyles((theme) => ({
  backgroundQuestion: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundImage: `url('${bgHome}')`,
    backgroundPositionX: '-11px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('xl')]: {
      backgroundPositionX: '0px'
    },
    [theme.breakpoints.down('lg')]: {
      backgroundPositionX: '-20px'
    },
    [theme.breakpoints.down('md')]: {
      backgroundPositionX: '-70px'
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPositionX: '-125px'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPositionX: '150px',
      position: 'relative',
      backgroundImage: `url('${bgHomeMobile}')`,
    }
  },
  buttonNextQuestion: {
    backgroundColor: '#d52e5f',
    color: '#fff',
    [theme.breakpoints.up('xl')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 17
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  buttonBack: {
    paddingRight: '30px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 17
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  marcaQuest: {
    paddingLeft: '5px',
    top: 0,
    left: 0,
    [theme.breakpoints.up('xl')]: {
      width: '220px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '220px'
    },
    [theme.breakpoints.down('md')]: {
      width: '150px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '110px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  marcaQuestMobile: {
    paddingLeft: '15px',
    paddingTop: '15px',
    top: 0,
    left: 0,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '115px'
    }
  },
  logoQuestions: {
    textAlign: 'left'
  },
  helloStranger: {
    textAlign: 'left',
    fontWeight: 'bolder',
    fontSize: 40
  },
  initStranger: {
    textAlign: 'left',
    paddingTop: '20px',
    fontSize: 20
  },
  buttonContainer: {
    paddingTop: '20px'
  },
  centralDiv: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centralQuestion: {
    [theme.breakpoints.up('xl')]: {
      padding: '250px 375px',
      minWidth: '1000px',
      maxWidth: '50%'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '200px 0px',
      minWidth: '700px',
      padding: '200px 250px',
      maxWidth: '45%'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '500px',
      padding: '200px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '320px',
      padding: '200px 150px',
      maxWidth: '40%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '200px',
      padding: '150px 40px',
      marginLeft: '20px',
      maxWidth: '50%'
    }
  },
  footerQuest: {
    textAlign: 'center',
    bottom: 0,
    position: 'fixed',
    backgroundColor: '#232020',
    height: '55px',
    zIndex: '0',
    left: 0,
    right: 0
  },
  footerCount: {
    textAlign: 'center'
  },
  stepperRoot: {
    justifyContent: 'center',
    marginTop: '-10px',
    backgroundColor: 'transparent'
  }
}))

Question.defaultProps = {
  questions: []
}

function alertSendMail() {
  swal("Respostas enviadas!", "Entraremos em contato em breve", "success").then(function () {
    location.href = 'https://www.rdmapps.com.br/'
  })
}

function alertSendMailError() {
  swal("Respostas não enviadas!", "Ocorreu um erro", "error").then(function () {
    location.href = 'https://www.rdmapps.com.br/'
  })
}

function Question({ questions }) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [validStep, setValidStep] = React.useState(false)
  const [data, setData] = useState(questions)
  const [checked, setChecked] = useState(true)
  
  function keyListener(event, question) {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      if (questions[questions.length - 1].id == question.id){
        sendSurvey(event)
      } else {
        handleNext()
      }
    }
  }

  useEffect(() => {
    setData(questions)
  }, [questions])

  function updateData(id, value){
    const newData = [...data]
    const questionIndex = newData.findIndex(question => question.id === id)
    newData[questionIndex].value = value
    setData(newData)
  }

  const genericQuestion = (question) => {
    if (question != undefined) {
      switch (question.answer_type) {
        case 'text':
          return <QuestionText setChecked={setChecked} keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'bigtext':
          return <QuestionBigText setChecked={setChecked} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'number':
          return <QuestionNumber setChecked={setChecked} keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'currency':
          return <QuestionCurrency setChecked={setChecked} keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'yes_no':
          return <QuestionYesNo setChecked={setChecked} keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'selection_list':
          return <QuestionSelectionList setChecked={setChecked} keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'fixed_value':
          return <QuestionFixedValue keyListener={keyListener} key={question.id} question={question} setValidStep={setValidStep} />
        case 'title':
          return <QuestionTitle keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        case 'checkbox_selection_list':
          return <QuestionCheckboxSelectionList keyListener={keyListener} key={question.id} question={question} updateData={updateData} setValidStep={setValidStep} />
        default:
          break
      }
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setValidStep(false)
    setChecked(false)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setValidStep(false)
    setChecked(true)
  }
  
  // TODO:####### AJUSTAR #######
  const [errors, setErrors] = useState({})
  
  function sendSurvey(e) {
    e.preventDefault()
    if (data){
      let dataMissing = []
      data.forEach(dataForm => {
        if (dataForm.answer_type != "yes_no" && dataForm.answer_type != "fixed_value" && dataForm.answer_type != "title"){
          if (dataForm.required == true && (dataForm.value == null || dataForm.value == "")){
            dataMissing.push({ type: dataForm.answer_type, required: dataForm.required, value: dataForm.value })
          }
        }
      })
      if (dataMissing.length == 0){
        axios.post('/subjects/public_create', { subject: data })
          .then(function () {
            alertSendMail()
          })
          .catch(function (response) {
            if (response.status == 422 || response.status == 500) {
              setErrors(response.data.errors)
            } else {
              setErrors({})
            }
            if (response.data && response.data.error) {
              setErrors(response.data.errors)
              alertSendMailError()
            } else {
              setErrors({})
            }
          })
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.backgroundQuestion}>
        <div className={classes.logoQuestions}>
          <img src={MarcaQuest} alt='roadmapps' className={classes.marcaQuest} />
          <img src={MarcaQuestMobile} alt='roadmapps-mobile' className={classes.marcaQuestMobile} />
        </div>
        <div className={classes.centralDiv}>
          <div className={classes.centralQuestion}>
            <form noValidate autoComplete="off" onSubmit={sendSurvey}>
              <div>{genericQuestion(questions[activeStep])}</div>
              <div className={classes.buttonContainer} >
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.buttonBack}>
                  Voltar
                </Button>
                {questions.length === activeStep + 1 ?  '' : (
                  <Button disabled={!validStep} variant="contained" color="primary" onClick={handleNext} className={classes.buttonNextQuestion}>
                    Próxima
                  </Button>
                )}
                {activeStep + 1 != questions.length ? '' : (
                  <Button disabled={!validStep} variant="contained" color="primary" type="submit" className={classes.buttonNextQuestion}>
                    Enviar
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={classes.footerQuest}>
          <Typography style={{ color: 'white', padding: '5px' }}>{(activeStep + 1) + ' DE ' + (questions.length)}</Typography>
          <MobileStepper className={classes.stepperRoot} variant="progress" steps={questions.length} position="static" activeStep={activeStep} />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Question