import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import DoneIcon from '@material-ui/icons/Done'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { motion } from 'framer-motion'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'BrandonGrotesque',
        [breakpoints.up('xl')]: {
          fontSize: 24
        },
        [breakpoints.down('lg')]: {
          fontSize: 22
        },
        [breakpoints.down('md')]: {
          fontSize: 20
        },
        [breakpoints.down('sm')]: {
          fontSize: 17
        },
        [breakpoints.down('xs')]: {
          fontSize: 14
        }
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: '0px',
        marginRight: '0px',
        borderRadius: '10px',
        marginBottom: '8px',
        justifyContent: 'space-between',
        paddingLeft: '10px'
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&.Mui-checked:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  backgroundQuestionCheckboxSelectionList: {
    height: 'auto',
    fontFamily: 'BrandonGrotesque',
    textAlign: 'left',
    paddingBottom: '50px',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '120px',
      width: '27px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        width: '70px',
        height: '310px',
        marginLeft: '-150px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '40px',
        height: '135px',
        marginLeft: '-75px'
      },
      [theme.breakpoints.down('md')]: {
        width: '37px',
        height: '135px',
        marginLeft: '-70px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '110px',
        marginLeft: '-55px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '50px',
        marginLeft: '-25px',
        marginTop: '10px'
      }
    }
  },
  titleCheckboxSelectionList: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: '10px'
    }
  },
  hintCheckboxSelectionList: {
    marginBottom: '20px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 42,
      padding: '15px 0px 50px 0px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      paddingTop: '5px'
    }
  }
}))

function QuestionCheckboxSelectionList({ keyListener, question, updateData, setValidStep }) {
  const classes = useStyles()
  const options = question.list_options.split("\n")
  const values = (question.value || "").split("\n")
  const [fieldError, setFieldError] = useState("")
  const checkedColor = { backgroundColor: 'transparent', border: '1px solid #d52e5f' }
  const notCheckedColor = { backgroundColor: 'lightgray', border: '1px solid lightgray' }

  function checkColor(value){
    return value ? checkedColor : notCheckedColor
  }

  useEffect(() => {
    handleValidation(values)
  }, [])

  function handleKeyListener(event) {
    if (handleValidation(values)){
      keyListener(event, question)
    }
  }

  function handleChange(option) {
    let newValues = ''
    if (values.includes(option)) {
      newValues = values.filter(v => v !== option)
    } else {
      newValues = values.concat(option)
    }
    updateData(question.id, newValues.join("\n"))
    handleValidation(newValues)
  }

  
  function handleValidation(newList = []){
    if (newList.filter(e => e).length == 0) {
      setValidStep(false)
      return false
    } else {
      setFieldError("")
      setValidStep(true)
      return true
    }
  }
  
  const handleChangeSingle = (option) => {
    let newValues = ''
    if (values.includes(option)) {
      newValues = values.filter(v => v !== option)
    } else {
      newValues = [option]
    }
    updateData(question.id, newValues.join("\n"))
    handleValidation(newValues)
  }

  return (
    <ThemeProvider theme={theme}>
      <motion.div initial={{ x: '30vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', stiffness: 40 }}>
        <div className={classes.backgroundQuestionCheckboxSelectionList} onKeyDown={handleKeyListener}>
          <Typography className={classes.titleCheckboxSelectionList}>{question.title}</Typography>
          <Typography className={classes.hintCheckboxSelectionList}>{question.hint}</Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {
              options.map((option) => (
                question.allowed_amount_of_answers > 1 ?
                  <FormControlLabel key={option} style={checkColor(values.includes(option))}
                    control={
                    <Checkbox onChange={() => handleChange(option)} 
                    checked={values.includes(option)} icon={<DoneIcon style={{color: 'transparent' }} />} checkedIcon={<DoneIcon style={{ color: '#d52e5f', fontSize: '24px' }} />} id='optionQuestion' name='optionQuestion' />}
                    label={option} labelPlacement="start"
                  />
                :
                <FormControlLabel key={option} style={checkColor(values.includes(option))}
                    control={<Checkbox onChange={() => handleChangeSingle(option)} checked={values.includes(option)} icon={<DoneIcon style={{color: 'transparent' }} />} checkedIcon={<DoneIcon style={{ color: '#d52e5f', fontSize: '24px' }} />} id='optionQuestionSingle' name='optionQuestionSingle' />}
                    label={option} labelPlacement="start"
                  />
                ))
              }
            </FormGroup>
          </FormControl>
          {fieldError ? <div style={{ color: 'red' }}>{fieldError}</div> : null}
        </div>
      </motion.div>
    </ThemeProvider>
  )
}

export default QuestionCheckboxSelectionList