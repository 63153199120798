import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Question from '../components/Question'

function Questions() {
  const [data, setData] = useState({})

  function fetchData() {
    axios.get('/api/questions.json?slug=' + window.location.href.split("/").filter(p => p != "").pop())
    .then(function (res) {
      console.log("Questions fetched")
      setData(res.data || {})
    })
  }

  useEffect(function () { fetchData() }, [])

  return (
    <Question questions={data.questions} />
  )
}

export default Questions