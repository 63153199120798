import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import EmptyCel from 'images/borda_cel.png'
import BlackCel from 'images/borda_cell_sm.png'
import InsideCel from 'images/tela_cel.png'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Carousel from '@brainhubeu/react-carousel'
import { Link } from 'react-scroll'
import '@brainhubeu/react-carousel/lib/style.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#E7E7E7',
    color: '#232020',
    maxWidth: 270,
    fontSize: 18,
    border: '1px solid #d52e5f',
  }
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  backgroundClients: {
    height: 'auto',
    backgroundColor: '#E7E7E7',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    // [theme.breakpoints.up('md')]: {
    //   maxHeight: '470px'
    // }
  },
  smartphoneImg: {
    marginTop: '-65px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  smartphoneClients: {
    width: '35%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  clients: {
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%'
    },
    display: 'flex',
    flexDirection: 'column'
  },
  headerClient: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  clientTitle: {
    color: '#797979',
    "&::after": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '130px',
      width: '35px',
      marginLeft: '30px',
      marginTop: '-20px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '100px'
      }
    },
    fontSize: 42,
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  clientCompanyName: {
    color: '#797979',
    fontSize: 42,
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  clientCompanyDesc: {
    fontSize: 14,
    minHeight: '150px',
    padding: '5px 10px 5px 0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  clientCompanyNameSm: {
    color: '#797979',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 26
    }
  },
  clientCompanyDescSm: {
    color: '#797979',
    backgroundColor: '#FFFFFF',
    fontSize: 14,
    padding: '10px',
    borderRadius: '0px 0px 20px 20px'
  },
  clientCompanyNameHeader: {
    paddingLeft: '105px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '75px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  clientHeaderSm: {
    textAlign: 'center',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 120px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px'
    }
  },
  clientCompanyNameHeaderSm: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      height: '260px',
    },
    backgroundColor: '#ddd',
    overflow: 'hidden',
    height: '300px',
    maxWidth: '100%',
    position: 'relative',
    padding: '20px',
    filter: 'drop-shadow(0px 0px 10px grey)',
    borderRadius: '20px 20px 0px 0px'
  },
  logoClients: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: '30px',
    paddingRight: '150px',
    paddingTop: '25px',
    justifyContent: 'center',
    // [theme.breakpoints.down('md')]: {
    //   paddingRight: '80px'
    // },
    [theme.breakpoints.down('md')]: {
      paddingRight: '10px'
    },
    "& img:hover": { filter: 'invert(61%) sepia(100%) saturate(5891%) hue-rotate(325deg) brightness(75%) contrast(91%)' }
  },
  clientLogo: {
    padding: '15px 60px',
    width: '105px',
    [theme.breakpoints.down('md')]: {
      width: '102px',
      padding: '20px 51px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      padding: '35px 47px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '105px',
      padding: '35px 10px'
    }
  },
  cellphoneImg: {
    height: '540px',
    filter: 'drop-shadow(0px 0px 3px black)',
    display: 'flex',
    zIndex: 3
  },
  insideCellPhoneImg: {
    display: 'flex',
    position: 'absolute',
    width: '245px',
    height: '515px',
  },
  phoneDiv: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: '50px'
    }
  },
  carrosselApps: {
    width: '240px',
    position: 'absolute',
    marginTop: '2px',
    marginLeft: '-1px'
  },
  carrosselImgs: {
    height: '550px',
    marginTop: '-7px',
    marginLeft: '1px',
    borderRadius: '35px'
  },
  cellphoneBlackImg1: {
    [theme.breakpoints.down('xs')]: {
      top: '45px'
    },
    position: 'absolute',
    top: '30px',
    left: '36%',
    zIndex: 10,
    width: '60%'
  },
  cellphoneBlackImg2: {
    [theme.breakpoints.down('xs')]: {
      top: '135px'
    },
    position: 'absolute',
    top: '120px',
    left: '20px',
    zIndex: 8,
    width: '50%'
  },
  cellphoneBlack1: {
    [theme.breakpoints.down('xs')]: {
      top: '50px',
      left: '37.5%'
    },
    position: 'absolute',
    top: '37px',
    left: '37%',
    zIndex: 9,
    width: '57%',
    borderRadius: '10%'
  },
  cellphoneBlack2: {
    [theme.breakpoints.down('xs')]: {
      top: '137px'
    },
    position: 'absolute',
    top: '122px',
    left: '23px',
    zIndex: 7,
    width: '57%',
    borderRadius: '10%'
  },
  clientCompanyInfoSm: {
    filter: 'drop-shadow(0px 0px 10px grey)',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  clientImgsSm: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  clientImgsLg: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

Clients.defaultProps = {
  portfolio_projects: []
}

function Clients({portfolio_projects}) {
  const classes = useStyles()
  const [galleryIndex, setGalleryIndex] = useState(0)
  const [galleryBanner, setgalleryBanner] = useState(null)
  const [galleryImg, setgalleryImg] = useState(null)
  const [titleClient, setTitleClient] = useState('')
  const [descriptionClient, setDescriptionClient] = useState('')
  const [filterActive, setFilterActive] = useState(false)
  const [clientIndex, setClientIndex] = useState(0)
  const anchorRef = React.useRef(null)

  useEffect(() => {
    Aos.init({ duration: 1200 })
  }, [])

  useEffect(() => {
    if (portfolio_projects[0]) {
      setTitleClient(portfolio_projects[0].clients.client_name)
      setDescriptionClient(portfolio_projects[0].description)
      setgalleryBanner(portfolio_projects[0].image_banner)
      setgalleryImg(portfolio_projects[0].image)
      setClientIndex(portfolio_projects[0].clients.id)
    }
  }, [portfolio_projects])

  function onChange(e){
    if (galleryIndex){
      setGalleryIndex(galleryIndex)
      return galleryIndex
    }
    return e
  }

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
  }

  function changeClientImg(id) {
    const portifolioIndex = portfolio_projects.findIndex(portifolio =>{
      return portifolio.client_ids.includes(id)
    })
    setGalleryIndex(portifolioIndex)
    setgalleryImg(portfolio_projects[portifolioIndex].image)
    setgalleryBanner(portfolio_projects[portifolioIndex].image_banner)
    setTitleClient(portfolio_projects[portifolioIndex].clients.client_name)
    setDescriptionClient(portfolio_projects[portifolioIndex].description)
    setFilterActive(!filterActive)
    setClientIndex(id)
  }

  return (
    <div className={classes.backgroundClients} id='clients'>
      <div className={classes.smartphoneClients}>
        <div data-aos="fade-up" className={classes.phoneDiv}>
          <img src={EmptyCel} alt='cellphone' className={classes.cellphoneImg} />
          <img src={InsideCel} alt='cellphone' className={classes.insideCellPhoneImg} />
          <div className={classes.carrosselApps}>
            <Carousel className={classes.carrosselImgs}
              value={galleryIndex}
              onChange={(e) => {
                onChange(e)
              } }
              infinite
            >
              {portfolio_projects.map((portfolio_project) => (
                <div id={'portifolio'} key={portfolio_project.name} style={{ width: '100%', height: 515, backgroundImage: `url(${portfolio_project.image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className={classes.clients}>
        <div className={classes.headerClient}>
          <div data-aos="slide-up"><Typography className={classes.clientTitle}>CLIENTES</Typography></div>
          <div data-aos="slide-up" className={classes.clientCompanyNameHeader}>
            <Typography className={classes.clientCompanyName}>{titleClient}</Typography>
            <Typography className={classes.clientCompanyDesc}>{descriptionClient}</Typography>
          </div>
        </div>
        <div data-aos="fade-up" className={classes.logoClients}>
          {portfolio_projects.map((portfolio_project) => (
            <div key={portfolio_project.clients.id}>
              <div className={classes.clientImgsSm}>
                <ClickAwayListener onClickAway={handleClickAway} key={portfolio_project.clients.id}>
                  <Link activeClass="activemobile" to="clientImg" spy={true} smooth={true} offset={-70} duration={1000} href='#clientImg' className={classes.menuItemDrop} color='inherit'>
                    <img style={clientIndex == portfolio_project.clients.id ? { filter: 'invert(61%) sepia(100%) saturate(5891%) hue-rotate(325deg) brightness(75%) contrast(91%)' } : { filter: 'none' }} key={portfolio_project.clients.id} src={portfolio_project.clients.logo} alt='client' className={classes.clientLogo} data={portfolio_project.id} onClick={() => changeClientImg(portfolio_project.clients.id)} />
                  </Link>
                </ClickAwayListener>
              </div>
              <div className={classes.clientImgsLg}>
                <img style={clientIndex == portfolio_project.clients.id ? { filter: 'invert(61%) sepia(100%) saturate(5891%) hue-rotate(325deg) brightness(75%) contrast(91%)' } : { filter: 'none' }} key={portfolio_project.clients.id} src={portfolio_project.clients.logo} alt='client' className={classes.clientLogo} data={portfolio_project.id} onClick={() => changeClientImg(portfolio_project.clients.id)} />
              </div>
            </div>
          ))}
        </div>
        <div className={classes.clientHeaderSm} id='clientImg'>
          <div className={classes.clientCompanyNameHeaderSm}>
            <img src={BlackCel} alt='cellphonesm' className={classes.cellphoneBlackImg1} />
            <img src={BlackCel} alt='cellphonesm' className={classes.cellphoneBlackImg2} />
            <img src={galleryImg} alt='apps_sm' className={classes.cellphoneBlack1} />
            <img src={galleryBanner} alt='apps_sm' className={classes.cellphoneBlack2} />
          </div>
          <div className={classes.clientCompanyInfoSm}>
            <Typography className={classes.clientCompanyNameSm}>{titleClient}</Typography>
            <Typography className={classes.clientCompanyDescSm}>{descriptionClient}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients