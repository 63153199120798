import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
  backgroundQuestionYesNo: {
    height: 'auto',
    outlineColor: 'transparent',
    fontFamily: 'BrandonGrotesque',
    textAlign: 'left',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '120px',
      width: '27px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        width: '70px',
        height: '310px',
        marginLeft: '-150px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '40px',
        height: '135px',
        marginLeft: '-75px'
      },
      [theme.breakpoints.down('md')]: {
        width: '37px',
        height: '135px',
        marginLeft: '-70px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '110px',
        marginLeft: '-55px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '50px',
        marginLeft: '-25px',
        marginTop: '10px'
      }
    }
  },
  titleYesNo: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: '10px'
    }
  },
  hintYesNo: {
    marginBottom: '20px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 42,
      padding: '15px 0px 50px 0px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      paddingTop: '5px'
    }
  },
  fieldYesNo: {
    paddingBottom: '10px',
    [theme.breakpoints.up('xl')]: {
      paddingBottom: '80px',
    },
    '& input': {
      [theme.breakpoints.up('xl')]: {
        fontSize: 42,
        width: '1000px'
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 25,
        width: '750px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        width: '550px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        width: '350px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
        width: '220px'
      }
    }
  },
  QuestionYesNoLabel:{
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  }
}))

const breakpoints = createBreakpoints({})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 65,
    height: 40,
    padding: 4,
    display: 'flex',
    [breakpoints.down('md')]: {
      width: 63,
      height: 35
    },
    [breakpoints.down('sm')]: {
      width: 58,
      height: 34
    },
    [breakpoints.down('xs')]: {
      width: 53,
      height: 29
    }
  },
  switchBase: {
    padding: 3,
    marginTop: '5.2px',
    marginLeft: '6px',
    color: '#d52e5f',
    '&$checked': {
      marginLeft: '16px',
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#d52e5f',
        borderColor: '#d52e5f',
      },
    },
  },
  thumb: {
    width: 25,
    height: 25,
    boxShadow: 'none',
    [breakpoints.down('md')]: {
      width: 20,
      height: 20
    },
    [breakpoints.down('sm')]: {
      width: 20,
      height: 20
    },
    [breakpoints.down('xs')]: {
      width: 15,
      height: 15
    }
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '20px',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

function QuestionYesNo({ setChecked, keyListener, question, updateData, setValidStep }) {
  const classes = useStyles()
  const divRef = useRef(null)

  useEffect(() => {
    setValidStep(true)
    divRef.current.focus()
  }, [])
  
  const handleChange = (e) => {
    updateData(question.id, e.target.checked)
    // setCheck({ ...check, [e.target.name]: e.target.checked })
  }

  function handleKeyListener(event) {
    keyListener(event, question)
  }

  return (
    <motion.div initial={{ x: '30vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', stiffness: 40 }}>
      <div ref={divRef} className={classes.backgroundQuestionYesNo} onKeyDown={handleKeyListener} tabIndex={-1}>
        <Typography className={classes.titleYesNo}>{question.title}</Typography>
        <Typography className={classes.hintYesNo}>{question.hint}</Typography>
        <RadioGroup className={classes.fieldYesNo} id='questionYesNo' name='questionYesNo' aria-label="radio" name="radioQuestion">
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={classes.QuestionYesNoLabel}>Não</Grid>
              <Grid item>
                <AntSwitch checked={question.value || false} onChange={handleChange} name="checkedYesNo" />
              </Grid>
              <Grid item className={classes.QuestionYesNoLabel}>Sim</Grid>
            </Grid>
          </Typography>
        </RadioGroup>
      </div>
    </motion.div>
  )
}

export default QuestionYesNo