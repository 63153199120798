import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Aos from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
  backgroundTeam: {
    height: 'auto'
  },
  titleTeam: {
    textAlign: 'center',
    paddingTop: '50px',
    fontSize: 43,
    color: '#3E3E3F',
    [theme.breakpoints.down('md')]: {
      paddingTop: '90px',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
      fontSize: 35
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '60px',
      fontSize: 28
    }
  },
  subTitleTeam: {
    textAlign: 'center',
    padding: '30px 200px',
    fontSize: 18,
    color: '#3E3E3F',
    [theme.breakpoints.down('md')]: {
      padding: '30px 100px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 100px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px'
    }
  },
  teamCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  memberTeamCard: {
    height: '300px',
    [theme.breakpoints.up('xl')]: {
      padding: '10px 5px 30px 5px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 5px 30px 5px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 8px 12px 8px'
    }
  },
  teamMember: {
    height: '300px',
    maxWidth: '230px'
  },
  teamMemberInfo: {
    backgroundColor: '#232020',
    opacity: '0.8',
    marginTop: '-58px'
  },
  memberName: {
    color: 'white',
    padding: '5px 5px 0px 5px',
    fontWeight: '400',
    marginLeft: '12px'
  },
  memberFunction: {
    color: 'white',
    padding: '0px 5px 5px 5px',
    fontWeight: '400',
    fontSize: '0.85rem',
    marginLeft: '12px'
  },
  teamCardCarroussel: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingBottom: '50px'
    }
  },
  arrowLeftAndRight: {
    color: '#d52e5f',
    fontSize: 30,
    borderRadius: '5px'
  }
}))

Team.defaultProps = {
  team: []
}
function Team({team}) {
  const classes = useStyles()

  useEffect(() => {
    Aos.init({ duration: 1200 })
  }, [])

  return (
    <div className={classes.backgroundTeam} id='team'>
      <div className={classes.teamHeader}>
        <Typography data-aos="fade-up" className={classes.titleTeam}>TIME</Typography>
        {/* <Typography data-aos="fade-up" className={classes.subTitleTeam}>Nós criamos produtos sob medida e soluções para todos os tipo de plataformas e dispositivos. Android, iOS, utilizando tecnologia nativa, que é mais rápida e fluida, e aplicações Web, que funcionam em qualquer computador.</Typography> */}
      </div>
      <div className={classes.teamCards}>
        {team.map((team) => (
          <div key={team.id} className={classes.memberTeamCard}>  
            <img data-aos="fade-up" src={team.image} alt={team.description || "member"} className={classes.teamMember} />
            <div className={classes.teamMemberInfo}>
              <Typography data-aos="fade-up" className={classes.memberName}>{team.name}</Typography>
              <Typography data-aos="fade-up" className={classes.memberFunction}>{team.job_title}</Typography>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.teamCardCarroussel}>
        <Carousel
          slidesPerPage={3}
          infinite
          arrows
          arrowLeft={<ArrowBackIosIcon className={classes.arrowLeftAndRight}/>}
          arrowRight={<ArrowForwardIosIcon className={classes.arrowLeftAndRight}/>}
          addArrowClickHandler
          breakpoints={{
            640: {
              slidesPerPage: 1,
              arrows: true
            },
            900: {
              slidesPerPage: 2,
              arrows: true
            }
          }}
        >
          {team.map((team) => (
            <div key={team.id}>
              <img src={team.image} alt={team.description || "member"} style={{ width: '100%', backgroundImage: `url(${team.image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></img>
              <div className={classes.teamMemberInfo}>
                <Typography className={classes.memberName}>{team.name}</Typography>
                <Typography className={classes.memberFunction}>{team.job_title}</Typography>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Team