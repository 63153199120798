import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { motion } from 'framer-motion'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  typography: {
    fontFamily: 'BrandonGrotesque'
  },
  overrides: {
    MuiInputBase: {
      root: {
        [breakpoints.up('xl')]: {
          fontSize: '2rem'
        },
        fontSize: '1.5rem',
        [breakpoints.down('md')]: {
          fontSize: '1rem'
        }
      }
    },
    MuiMenuItem: {
      root: {
        [breakpoints.up('xl')]: {
          fontSize: '2rem'
        },
        fontSize: '1.5rem',
        [breakpoints.down('md')]: {
          fontSize: '1rem'
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#d52e5f !important'
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backgroundQuestionSelectionList: {
    height: 'auto',
    fontFamily: 'BrandonGrotesque',
    textAlign: 'left',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '120px',
      width: '27px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        width: '70px',
        height: '310px',
        marginLeft: '-150px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '40px',
        height: '135px',
        marginLeft: '-75px'
      },
      [theme.breakpoints.down('md')]: {
        width: '37px',
        height: '135px',
        marginLeft: '-70px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '110px',
        marginLeft: '-55px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '50px',
        marginLeft: '-25px',
        marginTop: '10px'
      }
    }
  },
  titleSelectionList: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: '10px'
    }
  },
  hintSelectionList: {
    marginBottom: '20px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 42,
      padding: '15px 0px 50px 0px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      paddingTop: '5px'
    }
  },
  optionQuestion: {
    fontFamily: 'BrandonGrotesque'
  },
  formControl: {
    paddingBottom: '50px',
    width: '100%'
  }
}))

function QuestionSelectionList({ setChecked, keyListener, question, updateData, setValidStep }) {
  const classes = useStyles()
  
  useEffect(() => {
    if (question.value) {
      handleValidation()
    }
    setChecked(true)
  }, [])

  function handleKeyListener(event) {
    if (handleValidation()) {
      keyListener(event, question)
    }
  }

  function validate(question) {
    const value = question.value
    if (question.required) {
      if (value == "" || value == null) {
        return false
      } else {
        return true
      }
    } else if (!question.required) {
      return true
    }
  }

  function handleValidation() {
    const isValid = validate(question)
    if (!isValid) {
      setValidStep(false)
      return false
    } else {
      setValidStep(true)
      return true
    }
  }

  const handleChange = (e) => {
    updateData(question.id, e.target.value)
    handleValidation()
  }

  return (
    <ThemeProvider theme={theme}>
      <motion.div initial={{ x: '30vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', stiffness: 40 }}>
        <div className={classes.backgroundQuestionSelectionList} onKeyDown={handleKeyListener}>
          <Typography className={classes.titleSelectionList}>{question.title}</Typography>
          <Typography className={classes.hintSelectionList}>{question.hint}</Typography>
          <FormControl className={classes.formControl}>
            <Select
              autoFocus
              value={question.value || ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              id='questionSelectionList'
              name='questionSelectionList'
              variant='outlined'
              className={classes.selectEmpty}
            >
              <MenuItem value="" disabled><em>Selecione uma opção</em></MenuItem>
              {question.list_options.split('\n').map((option_question) => (
                <MenuItem key={option_question} value={option_question}>
                  {option_question}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </motion.div>
    </ThemeProvider>
  )
}

export default QuestionSelectionList