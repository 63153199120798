import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import bgWhereWeAre from 'images/fundo2.png'
import imgMarker from 'images/pin.png'
import Typography from '@material-ui/core/Typography'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const exampleMapStyles = [
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  }
]


const MyMapComponent = withScriptjs(withGoogleMap((props) => {
  let markerPosition = {}
  window.matchMedia("(max-width: 500px)").matches ? markerPosition = {lat: -8.062310, lng: -34.871692} : markerPosition = {lat: -8.062371, lng: -34.872710 }
  
  return(
  <GoogleMap defaultZoom={18} defaultCenter={markerPosition}
  options={{gestureHandling: 'greedy', scrollwheel: false, styles: exampleMapStyles, fullscreenControl: false, streetViewControl: false }}>
    {props.isMarkerShown && <Marker icon={{ url: imgMarker }} position={{ lat: -8.062448, lng: -34.871652 }} />}
  </GoogleMap>
  )
}))

const useStyles = makeStyles((theme) => ({
  backgroundWhereWeAre: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    backgroundImage: `url('${bgWhereWeAre}')`,
    backgroundPositionX: 'left',
    height: '100%',
    padding: 0,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('xl')]: {
      backgroundSize: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '68%'
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '75%'
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '75%'
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '115%'
    }
  },
  whereDiv: {
    position: 'relative',
    zIndex: 2,
    color: '#FFFFFF',
    padding: '70px 120px',
    [theme.breakpoints.down('md')]: {
      padding: '60px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '35px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px'
    }
  },
  where: {
    fontSize: 30,
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      fontSize: 27
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  street: {
    fontSize: 24,
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  city: {
    fontSize: 24,
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  }
}))

function WhereWeAre() {
  const classes = useStyles()

  return (
    <div className={classes.backgroundWhereWeAre} id='whereweare' style={{ position: `relative`, height: `450px`}}>
      <div className={classes.backgroundWhereWeAre} style={{ pointerEvents: `none`}}>
      </div>
      <div className={classes.whereDiv} style={{ pointerEvents: `none`}}>
        <Typography className={classes.where}>ONDE ESTAMOS</Typography>
        <Typography className={classes.street}>Rua do Bom Jesus, 125, 3º andar</Typography>
        <Typography className={classes.city}>Recife - PE</Typography>
      </div>
      <MyMapComponent
        isMarkerShown
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDpkyU-ggEm6kdZ52-LmuG_5YoQFAWoGGo&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `500px` }} />}
        containerElement={<div style={{ height: `auto`, position: `absolute`, top: 0, right: 0, left: 0, zIndex: 0}} />}
        mapElement={<div style={{ height: `500px` }} />}
      />
    </div>
  )
}

export default WhereWeAre