import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
  backgroundQuestionTitle: {
    height: 'auto',
    outlineColor: 'transparent',
    fontFamily: 'BrandonGrotesque',
    textAlign: 'left',
    paddingBottom: '50px',
    "&::before": {
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '120px',
      width: '27px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        width: '70px',
        height: '310px',
        marginLeft: '-150px',
      },
      [theme.breakpoints.down('lg')]: {
        width: '40px',
        height: '135px',
        marginLeft: '-75px'
      },
      [theme.breakpoints.down('md')]: {
        width: '37px',
        height: '135px',
        marginLeft: '-70px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '110px',
        marginLeft: '-55px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '50px',
        marginLeft: '-25px',
        marginTop: '10px'
      }
    }
  },
  titleTitle: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 55
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: '10px'
    }
  }
}))

function QuestionTitle({ keyListener, question, setValidStep }) {
  const classes = useStyles()
  const divRef = useRef(null)
  
  useEffect(() => {
    setValidStep(true)
    divRef.current.focus()
  }, [])

  function handleKeyListener(event) {
    keyListener(event, question)
  }

  return (
    <motion.div initial={{ x: '30vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', stiffness: 40 }}>
      <div ref={divRef} className={classes.backgroundQuestionTitle} onKeyDown={handleKeyListener} tabIndex={-1}>
        <Typography className={classes.titleTitle}>{question.title}</Typography>
      </div>
    </motion.div>
  )
}

export default QuestionTitle